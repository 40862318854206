import * as THREE from 'three';

const loader = new THREE.TextureLoader();

const texture = loader.load('/textures/stone.png');

const stoneMaterial = new THREE.MeshBasicMaterial({
  map: texture,
  transparent: true,
});

export default stoneMaterial;
