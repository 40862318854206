import { ajax } from "jlight";
import * as THREE from 'three';
import createDebugSpot from "./utils/create-debug-spot";

const loadItems = (scene, camera) => {
  ajax({
    url: '/items.json',
    method: 'get',
    done: (response) => {
      response.forEach((buildingData) => {
        let item;

        switch (buildingData.Type) {
          case 'Tiered_Athena_FloorLoot_01_C':
            return;

          case 'BP_Athena_Environmental_ZipLine_Spline_C':
            const material = new THREE.LineBasicMaterial({
              color: 0x0000ff
            });

            const points = [new THREE.Vector3(), new THREE.Vector3()];

            points[0].set(
              buildingData.Start.X,
              buildingData.Start.Y,
              buildingData.Start.Z,
            );
            points[1].set(
              buildingData.End.X,
              buildingData.End.Y,
              buildingData.End.Z,
            );

            const geometry = new THREE.BufferGeometry().setFromPoints(points);

            item = new THREE.Line(geometry, material);

            break;

          default:
            item = createDebugSpot();

            item.position.set(
              buildingData.Position.X,
              buildingData.Position.Y,
              buildingData.Position.Z,
            );

            break;
        }

        item.name = buildingData.StaticId;

        // item.rotation.set(
        //   THREE.MathUtils.degToRad(buildingData.rotation.Pitch),
        //   THREE.MathUtils.degToRad(buildingData.rotation.Roll),
        //   THREE.MathUtils.degToRad(buildingData.rotation.Yaw),
        // );

        scene.add(item);
      })
    }
  });
};

export default loadItems;
