import createLine from '../utils/create-line'
import get3dDistance from '../utils/get-3d-distance';

export default (scene, shots) => {
  shots.forEach(({ hitPos, playerPos }) => {
    const distance = get3dDistance(hitPos, playerPos);

    if (distance > 1000) {
      return;
    }

    const line = createLine([hitPos, {
      ...playerPos,
      z: playerPos.z + 150,
    }], 'red');

    scene.add(line);
  });
}
