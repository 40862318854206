import * as THREE from 'three';

import safezoneMaterial from '../materials/safezone';

const safezones = (scene, safezones) => {
  safezones.forEach(({ NextCenter, NextRadius }) => {
    const geometry = new THREE.RingGeometry(NextRadius, NextRadius + 512, NextRadius / 100);

    const safezone = new THREE.Mesh(geometry, safezoneMaterial);

    safezone.position.set(
      NextCenter.x,
      NextCenter.y,
      10000,
    );

    safezone.userData.cantDespawn = true;

    scene.add(safezone);
  });
};

export default safezones;
