import * as THREE from 'three';

const loader = new THREE.TextureLoader();

const texture = loader.load('/textures/wood.png');

const woodMaterial = new THREE.MeshBasicMaterial({
  transparent: true,
  map: texture,
});

export default woodMaterial;
