import createDebugSpot from '../utils/create-debug-spot';
import createLine from '../utils/create-line';

const pawns = (scene, pawns) => {
  pawns.forEach(({ positionHistory }) => {
    const item = createLine(positionHistory.map((a) => ({ x: a.x, y: a.y, z: a.z })));

    item.userData.cantDespawn = true;
    item.userData.positionHistory = positionHistory;

    scene.add(item);
  });

  // const terrain = scene.getObjectByName('terrain');
  // console.log(pawns[137]);
  // const results = [];
  // const heightMap = {};

  // pawns[1].positionHistory.forEach(({ x, y, z, time }) => {
  //   const rightY = -y;
  //   const debugObject = createDebugSpot();

  //   debugObject.position.set(x, rightY, z);

  //   debugObject.userData.time = time;

  //   scene.add(debugObject);

  //   // if (time < 217 && time > 65) {
  //       const height = terrain.userData.heights[Math.floor((((x + 262500 / 2)) / 262500) * terrain.userData.rowLength)][Math.floor((262500 - (rightY + 262500 / 2)) / 262500 * terrain.userData.rowLength)];
  //       const realHeight = z;
  //       // { x, y: rightY, z },

  //       heightMap[height] = realHeight
  //   // }
  // });

  // console.log(heightMap);
};

export default pawns;
