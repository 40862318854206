import * as THREE from 'three';

const materials = {};

export default (positions, color = 'blue') => {
  const points = [];

  let material;

  if (materials[color]) {
    material = materials[color];
  } else {
    material = new THREE.LineBasicMaterial({
      color,
    });

    materials[color] = material;
  }

  positions.forEach(({ x, y, z }) => {
    const pos = new THREE.Vector3();

    pos.set(x, y, z);

    points.push(pos);
  });

  const geometry = new THREE.BufferGeometry().setFromPoints(points);

  const item = new THREE.Line(geometry, material);

  item.userData.cantDespawn = true;

  return item;
}
