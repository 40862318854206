import * as THREE from 'three';

export default (transparent = false) => {
  THREE.Object3D.DefaultUp = new THREE.Vector3(0, 0, 1);

  const scene = new THREE.Scene();

  scene.scale.y = -1;

  const camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 10, 500000);

  camera.name = 'camera';

  const renderer = new THREE.WebGLRenderer({ alpha: transparent });
  renderer.setSize(window.innerWidth, window.innerHeight);
  document.body.appendChild(renderer.domElement);

  window.addEventListener('resize', () => {
    console.log('resize');
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight, true);
  });

  return {
    scene,
    camera,
    renderer,
  }
};
