import * as THREE from 'three';
import { PNG } from 'pngjs/browser';
import { ajax } from 'jlight';
import mapMaterial from './materials/map';
import waterMaterial from './materials/water';
import createDebugSpot from './utils/create-debug-spot';
import createLine from './utils/create-line';

const highestVersion = 1;

const commands = {
  end: 0,
  layer: 1,
  repeat: 2,
  nextLine: 3,
};

const mapSize = 250000;
const planeSize = 4096;
const gridSize = 200;

const halfMapSize = mapSize / 2;

export default (scene, camera) => {
  return new Promise((resolve) => {
    ajax({
      url: '/object-heightmap.bin',
      method: 'get',
      xhr: (xhr) => {
        xhr.responseType = 'arraybuffer';

        return xhr;
      },
      /**
       * @param {ArrayBuffer} response
       */
      done: (responsee) => {
        const view = new Int32Array(responsee);

        const reload = (test) => {
          const positions = [];

          for (let i = 0; i < view.length / 3; i += 3) {
            const x = view[i] * 100;
            const y = view[i + 1] * 100;
            const z = view[i + 2] * 100;

            if (isNaN(x) || isNaN(y) || isNaN(z)) {
              console.log('Invalid x, y, z', x, y, z);
              continue;
            }

            positions.push({ x, y, z });
          }

          const terrainGeometry = createLine(positions);

          scene.add(terrainGeometry);

          // const oldTerrain = scene.getObjectByName('terrain');

          // if (oldTerrain) {
          //   scene.remove(oldTerrain);
          // }

          // const terrain = new THREE.Mesh(terrainGeometry, mapMaterial);

          // terrain.name = 'terrain';
          // terrain.userData.cantDespawn = true
          // terrain.scale.y = -1;
          // // terrain.position.x = -100;
          // // terrain.position.y = 9750;

          // scene.add(terrain);
        }

        reload({ sub: 32760, dev: 1.28 });

        global.reload = reload;

        // for (let x = -262500 / 2; x < 262500 / 2; x += 2000) {
        //   for (let y = -262500 / 2; y < 262500 / 2; y += 2000) {
        //     const debugObject = createDebugSpot();

        //     debugObject.position.set(
        //       x,
        //       y,
        //       terrain.userData.heights[Math.floor((((x + 262500 / 2)) / 262500) * terrain.userData.rowLength)][Math.floor((262500 - (y + 262500 / 2)) / 262500 * terrain.userData.rowLength)] - 33388,
        //     );

        //     scene.add(debugObject);
        //   }
        // }

        resolve();
      }
    });
  });
}
