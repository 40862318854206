import * as THREE from 'three';
import { PNG } from 'pngjs/browser';
import { ajax } from 'jlight';
import mapMaterial from './materials/map';
import waterMaterial from './materials/water';
import createDebugSpot from './utils/create-debug-spot';
import createLine from './utils/create-line';

const highestVersion = 1;

const commands = {
  end: 0,
  layer: 1,
  repeat: 2,
  nextLine: 3,
};

export default (scene, camera) => {
  return new Promise((resolve) => {
    ajax({
      url: '/map.bin',
      method: 'get',
      xhr: (xhr) => {
        xhr.responseType = 'arraybuffer';

        return xhr;
      },
      /**
       * @param {ArrayBuffer} response
       * @returns
       */
      done: (responsee) => {
        const response = new Uint8Array(responsee);

        const reload = (test) => {
          const heights = [];

          let index = 2;
          let line = -1;
          let layer = 0;

          const version = response[0];
          const numbersInLayer = response[1];

          if (version > highestVersion) {
            console.log('This map is too new for this version of the game');

            return;
          }

          while (true) {
            const number = response[index];

            index += 1;

            if (number === commands.end) {
              break;
            }

            if (number === commands.layer) {
              const newLayer = response[index];
              index += 1;

              layer = newLayer;

              continue;
            }

            if (number === commands.repeat) {
              const count = response[index];
              const number = response[index + 1];
              index += 2;

              for (let i = 0; i < count; i += 1) {
                heights[line].push(layer * numbersInLayer + number);
              }

              continue;
            }

            if (number === commands.nextLine) {
              line += 1;

              heights[line] = [];

              continue;
            }

            heights[line].push((layer * numbersInLayer + number) - (255 - numbersInLayer));
          }

          const terrainGeometry = new THREE.PlaneGeometry(260096, 260096, heights[0].length - 1, heights.length - 1);

          heights.forEach((row, y) => {
            row.forEach((data, x) => {
              const index2 = (row.length * x + y) * 3;
              let height = (data - test.sub) / test.dev;

              if (height < 0) {
                terrainGeometry.attributes.position.array[index2 + 2] = 0;
              } else {
                terrainGeometry.attributes.position.array[index2 + 2] = height;
              }
            });
          });

          const oldTerrain = scene.getObjectByName('terrain');

          if (oldTerrain) {
            scene.remove(oldTerrain);
          }

          const terrain = new THREE.Mesh(terrainGeometry, mapMaterial);

          terrain.name = 'terrain';
          terrain.userData.cantDespawn = true
          terrain.userData.rowLength = heights[0].length;
          terrain.userData.heights = heights;
          terrain.scale.y = -1;
          // terrain.position.x = -100;
          // terrain.position.y = 9750;

          scene.add(terrain);
        }

        reload({ sub: 32760, dev: 1.28 });

        global.reload = reload;

        // for (let x = -262500 / 2; x < 262500 / 2; x += 2000) {
        //   for (let y = -262500 / 2; y < 262500 / 2; y += 2000) {
        //     const debugObject = createDebugSpot();

        //     debugObject.position.set(
        //       x,
        //       y,
        //       terrain.userData.heights[Math.floor((((x + 262500 / 2)) / 262500) * terrain.userData.rowLength)][Math.floor((262500 - (y + 262500 / 2)) / 262500 * terrain.userData.rowLength)] - 33388,
        //     );

        //     scene.add(debugObject);
        //   }
        // }

        resolve();
      }
    });
  });
}
