import * as THREE from 'three';

const loader = new THREE.TextureLoader();

const texture = loader.load('/textures/metal.png');

const metalMaterial = new THREE.MeshBasicMaterial({
  map: texture,
  transparent: true,
});

export default metalMaterial;
