import { ajax } from "jlight";

import playerBuilds from './replay/player-builds';
import pickups from './replay/pickups';
import safezones from './replay/safezones';
import pawns from "./replay/pawns";
import shots from './replay/shots';

const loadReplay = (scene, camera) => {
  ajax({
    url: '/replay.json',
    method: 'get',
    done: (response) => {
      playerBuilds(scene, response.mapData.playerBuilds, camera);
      // pickups(scene, response.mapData.pickups);
      safezones(scene, response.mapData.safeZones);
      pawns(scene, response.mapData.playerPawns);
      // shots(scene, response.mapData.shots);
    }
  });
};

export default loadReplay;
