import * as THREE from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import floorGeometry from '../geometry/floor';
import rampGeometry from '../geometry/ramp';
import wallGeometry from '../geometry/wall';
import debugSpotMaterial from '../materials/debug-spot';
import metalMaterial from '../materials/metal';
import stoneMaterial from '../materials/stone';

import woodMaterial from "../materials/wood";
import createText from './create-text';

const fbxLoader = new FBXLoader();

const redirects = {
  WindowsC: 'WindowC',
  HalfWall: 'HalfWallS',
  WindowsSide: 'WindowSide',
  HalfWallDoorSide: 'HalfWallDoorS',
};

const geometryOverrides = {
  Floor: floorGeometry,
  StairW: rampGeometry,
  Solid: wallGeometry,
  RoofS: rampGeometry,
  Floor_2: floorGeometry,
};

const loadFbx = async (type, material) => {
  const realType = redirects[type] || type;
  const object = await fbxLoader.loadAsync(`/models/${realType}.fbx`);

  object.children.forEach((ok) => {
    ok.material = material;
  });

  return object;
}

const getPlayerBuild = async (type, materialString) => {
  let material;

  switch (materialString) {
    case 'Wood':
      material = woodMaterial;
      break;

    case 'Metal':
      material = metalMaterial;
      break;

    case 'Stone':
      material = stoneMaterial;
      break;

    default:
      console.error('Unknown material', materialString);
      material = debugSpotMaterial;

      break;
  }

  if (geometryOverrides[type]) {
    const mesh = new THREE.Mesh(geometryOverrides[type], material);

    return mesh;
  }

  try {
    return await loadFbx(type, material);
  } catch (er) {
    const text = createText(type);

    text.userData.type = type;
    text.userData.missing = true;

    return text;
  }
}

export default getPlayerBuild;
