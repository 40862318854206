import * as THREE from 'three';

import pickupGeometry from '../geometry/pickup';
import pickupMaterial from '../materials/pickup';

const createPickup = () => {
  const floor = new THREE.Mesh(
    pickupGeometry,
    pickupMaterial,
  );

  return floor;
}

export default createPickup;
