import * as THREE from 'three';

const length = 50;
const radius = 20;

const debugSpotGeometry = new THREE.CylinderGeometry(0, radius, length);

debugSpotGeometry.translate(0, length / 2, 0);

debugSpotGeometry.rotateZ(Math.PI);

export default debugSpotGeometry;
