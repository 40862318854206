import { ajax } from "jlight";

import * as THREE from 'three';

import playerBuilds from './replay/player-builds';
import pickups from './replay/pickups';
import safezones from './replay/safezones';
import pawns from "./replay/pawns";
import createLine from './utils/create-line';
import getPlayerBuild from "./utils/get-player-build";
import createDebugSpot from './utils/create-debug-spot';
import JsonParser from 'json-parser';

const GRID_SIZE = 200;

// we need to get the height of the terrain at a specific x, y coordinate
// if it is not found, we need to interpolate the height from the surrounding coordinates
const getHeightAt = (x, y, ok) => {
  const gridX = Math.round(x / GRID_SIZE);
  const gridY = Math.round(y / GRID_SIZE);

  const height = ok[`${gridX},${gridY}`];

  if (height !== undefined) {
    if (height < 0) {
      return 0;
    }

    return height;
  }

  return 0;

  // search for the nearby grid for entries to interpolate from

  const nearby = [];

  for (let x = gridX - (GRID_SIZE * 3); x <= gridX + (GRID_SIZE * 3); x += GRID_SIZE) {
    for (let y = gridY - (GRID_SIZE * 3); y <= gridY + (GRID_SIZE * 3); y += GRID_SIZE) {
      const height = ok[`${x},${y}`];

      if (height !== undefined) {
        nearby.push({
          x,
          y,
          height,
        });
      }
    }
  }

  if (nearby.length === 0) {
    return 0;
  }

  // we have nearby entries, so we can interpolate the height
  // we will use the closest 4 entries to the point to do this

  const sorted = nearby.sort((a, b) => {
    const distanceA = Math.sqrt(Math.pow(a.x - x, 2) + Math.pow(a.y - y, 2));
    const distanceB = Math.sqrt(Math.pow(b.x - x, 2) + Math.pow(b.y - y, 2));

    return distanceA - distanceB;
  });

  const closest = sorted.slice(0, 2);

  const totalHeight = closest.reduce((acc, item) => {
    return acc + item.height;
  }, 0);

  return totalHeight / closest.length;
};

const test = (scene, camera) => {
  fetch('/object-heightmap.json').then(async (res) => {
    // const ok = await res.json();
    const ok = JsonParser(await res.text());
    let positions = [];

    let lastPos = null;

    for (let x = -150000; x < 150000; x += GRID_SIZE) {
      for (let y = -150000; y < 150000; y += GRID_SIZE) {
        // if (lastPos) {
        //   const distance = Math.sqrt(Math.pow(x - lastPos.x, 2) + Math.pow(y - lastPos.y, 2));

        //   if (distance > GRID_SIZE * 10) {
        //     const line = createLine(positions);

        //     scene.add(line);

        //     positions = [];
        //   }
        // }

        const thePos = {
          x,
          y,
          z: ok[`${x / GRID_SIZE},${y / GRID_SIZE}`],
        };

        positions.push(thePos);
        // lastPos = thePos;
      }
    }

    for (let y = -150000; y < 150000; y += GRID_SIZE) {
      for (let x = -150000; x < 150000; x += GRID_SIZE) {
        // if (lastPos) {
        //   const distance = Math.sqrt(Math.pow(x - lastPos.x, 2) + Math.pow(y - lastPos.y, 2));

        //   if (distance > GRID_SIZE * 10) {
        //     const line = createLine(positions);

        //     scene.add(line);

        //     positions = [];
        //   }
        // }

        const thePos = {
          x,
          y,
          z: ok[`${x / GRID_SIZE},${y / GRID_SIZE}`],
        };

        positions.push(thePos);
        // lastPos = thePos;
      }
    }

    // Object.entries(ok).forEach(([key, item]) => {
    //   const [xString, yString] = key.split(',');
    //   const x = parseFloat(xString);
    //   const y = parseFloat(yString);

    //   if (x < -150000 || x > 150000 || y < -150000 || y > 150000) {
    //     return;
    //   }

    //   if (lastPos) {
    //     const distance = Math.sqrt(Math.pow(x - lastPos.x, 2) + Math.pow(y - lastPos.y, 2));

    //     if (distance > 1000) {
    //       const line = createLine(positions);

    //       scene.add(line);

    //       positions = [];
    //     }
    //   }

    //   positions.push({
    //     x,
    //     y,
    //     z: item,
    //   });

    //   lastPos = {
    //     x,
    //     y,
    //     z: item,
    //   };

    //   // const line = createDebugSpot();

    //   // line.position.set(
    //   //   x,
    //   //   y,
    //   //   item,
    //   // );

    //   // line.userData.cantDespawn = true;
    //   // line.userData.key = key;

    //   // scene.add(line);
    // });

    const line = createLine(positions);

    scene.add(line);
  });

  return;
  ajax({
    url: '/raycasttest.json',
    method: 'get',
    done: (ok) => {
      const renderedPos = [];

      ok.hits.forEach((a) => {
        a.checks.forEach((b) => {
          const cast = createLine([
            b.startPos,
            {
              x: b.startPos.x + b.direction.x * b.distance,
              y: b.startPos.y + b.direction.y * b.distance,
              z: b.startPos.z + b.direction.z * b.distance,
            }
          ], 'red')

          scene.add(cast)

          b.result.forEach((result, index) => {
            // if (index !== 0) {
            //   return;
            // }

            result.face.forEach((face) => {
              const line = createLine([
                ...face.points.map((point) => point),
                face.points[0],
              ], 'purple');

              renderedPos.push(face.points[0]);

              scene.add(line);

              const center = face.points.reduce((acc, point) => {
                acc.x += point.x;
                acc.y += point.y;
                acc.z += point.z;

                return acc;
              }, { x: 0, y: 0, z: 0 });

              center.x /= face.points.length;
              center.y /= face.points.length;
              center.z /= face.points.length;

              const normal = createLine([{
                x: center.x,
                y: center.y,
                z: center.z,
              }, {
                x: center.x + face.normal.x * 50,
                y: center.y + face.normal.y * 50,
                z: center.z + face.normal.z * 50,
              }]);

              // scene.add(normal);
            });

            result.visibleFaces.forEach((face) => {
              if (renderedPos.some((pos) => pos.x === face.points[0].x && pos.y === face.points[0].y && pos.z === face.points[0].z)) {
                return;
              }

              const line = createLine([
                ...face.points.map((point) => point),
                face.points[0],
              ], 'green');

              renderedPos.push(face.points[0]);

              scene.add(line);

              const center = face.points.reduce((acc, point) => {
                acc.x += point.x;
                acc.y += point.y;
                acc.z += point.z;

                return acc;
              }, { x: 0, y: 0, z: 0 });

              center.x /= face.points.length;
              center.y /= face.points.length;
              center.z /= face.points.length;

              const normal = createLine([{
                x: center.x,
                y: center.y,
                z: center.z,
              }, {
                x: center.x + face.normal.x * 50,
                y: center.y + face.normal.y * 50,
                z: center.z + face.normal.z * 50,
              }]);

              // scene.add(normal);
            });

            const hitPos = createDebugSpot();

            hitPos.position.set(
              result.position.x,
              result.position.y,
              result.position.z,
            );

            scene.add(hitPos);
          });
        });
      })

      ok.models.forEach((model) => {
        model.faces.forEach((face) => {
          const renderedddd = renderedPos.filter((pos) => pos.x === face.points[0].x && pos.y === face.points[0].y && pos.z === face.points[0].z);

          // renderedddd.forEach((a) => {
          //   a.userData.model = model;
          // });

          if (renderedddd.length) {
            return;
          }

          const line = createLine([
            ...face.points,
            face.points[0],
          ]);

          line.userData.model = model;

          scene.add(line);

          const theCenter = createDebugSpot();

          theCenter.position.set(
            face.position.x,
            face.position.y,
            face.position.z,
          );

          const normal = createLine([{
            x: face.position.x,
            y: face.position.y,
            z: face.position.z,
          }, {
            x: face.position.x + face.normal.x * 50,
            y: face.position.y + face.normal.y * 50,
            z: face.position.z + face.normal.z * 50,
          }]);

          // scene.add(theCenter);
          // scene.add(normal);
        });
      });
    }
  });


  // const okkkkkk = createDebugSpot();

  // okkkkkk.position.set(-2593.84, 10139.57, 2521.83);

  // scene.add(okkkkkk);
  // scene.add(createLine([
  //   { x: 0, y: 0, z: 0 },
  //   { x: 1000, y: 0, z: 0 },
  // ]));

  return;

  ajax({
    url: '/stone.json',
    method: 'get',
    done: (response) => {
      response.Properties.SavedLevels.Value[0].Value.SavedActors.Value.forEach(async (actor) => {
        const match = actor.ActorPath.match(/\/Game\/Building\/ActorBlueprints\/Player\/(?<material>\w+)\/L\d\/PBWA_[a-z\d]+_(?<type>[a-z0-9_]+).+/i);

        if (!match) {
          return;
        }

        const { material, type } = match.groups;

        const object = await getPlayerBuild(type, material, camera);

        object.name = type;

        object.position.set(
          actor.Location.X,
          actor.Location.Y,
          actor.Location.Z,
        );

        object.rotation.setFromQuaternion(new THREE.Quaternion(
          actor.Rotation.X,
          actor.Rotation.Y,
          actor.Rotation.Z,
          actor.Rotation.W,
        ));

        object.scale.set(
          actor.Scale.X,
          actor.Scale.Y,
          actor.Scale.Z,
        );

        if (object.userData.missing) {
          camera.position.x = object.position.x;
          camera.position.y = -object.position.y;
          camera.position.z = object.position.z;
        }

        scene.add(object);
      });
      // response.forEach((item) => {
      //   if (item.Type === 'BodySetup' && item.Outer !== 'LakeCollisionComponent') {
      //     console.log(item);

      //     item.Properties.AggGeom.ConvexElems.forEach((convexElem) => {
      //       const points = [];

      //       convexElem.VertexData.forEach((vertex) => {
      //         points.push(new THREE.Vector3(vertex.X + convexElem.Transform.Translation.X, -vertex.Y + -convexElem.Transform.Translation.Y, vertex.Z + convexElem.Transform.Translation.Z));
      //       });

      //       const line = createLine(points);

      //       scene.add(line);
      //     });
      //   }
      // });
    }
  });
  ajax({
    url: '/plankerton.json',
    method: 'get',
    done: (response) => {
      response.Properties.SavedLevels.Value[0].Value.SavedActors.Value.forEach(async (actor) => {
        const match = actor.ActorPath.match(/\/Game\/Building\/ActorBlueprints\/Player\/(?<material>\w+)\/L\d\/PBWA_[a-z\d]+_(?<type>[a-z0-9_]+).+/i);

        if (!match) {
          return;
        }

        const { material, type } = match.groups;

        const object = await getPlayerBuild(type, material, camera);

        object.name = type;

        object.position.set(
          actor.Location.X,
          actor.Location.Y + 30000,
          actor.Location.Z,
        );

        object.rotation.setFromQuaternion(new THREE.Quaternion(
          actor.Rotation.X,
          actor.Rotation.Y,
          actor.Rotation.Z,
          actor.Rotation.W,
        ));

        object.scale.set(
          actor.Scale.X,
          actor.Scale.Y,
          actor.Scale.Z,
        );

        if (object.userData.missing) {
          camera.position.x = object.position.x;
          camera.position.y = -object.position.y;
          camera.position.z = object.position.z;
        }

        scene.add(object);
      });
    }
  });
  ajax({
    url: '/canny.json',
    method: 'get',
    done: (response) => {
      response.Properties.SavedLevels.Value[0].Value.SavedActors.Value.forEach(async (actor) => {
        const match = actor.ActorPath.match(/\/Game\/Building\/ActorBlueprints\/Player\/(?<material>\w+)\/L\d\/PBWA_[a-z\d]+_(?<type>[a-z0-9_]+).+/i);

        if (!match) {
          return;
        }

        const { material, type } = match.groups;

        const object = await getPlayerBuild(type, material, camera);

        object.name = type;

        object.position.set(
          actor.Location.X + 30000,
          actor.Location.Y,
          actor.Location.Z,
        );

        object.rotation.setFromQuaternion(new THREE.Quaternion(
          actor.Rotation.X,
          actor.Rotation.Y,
          actor.Rotation.Z,
          actor.Rotation.W,
        ));

        object.scale.set(
          actor.Scale.X,
          actor.Scale.Y,
          actor.Scale.Z,
        );

        if (object.userData.missing) {
          camera.position.x = object.position.x;
          camera.position.y = -object.position.y;
          camera.position.z = object.position.z;
        }

        scene.add(object);
      });
    }
  });
  ajax({
    url: '/twine.json',
    method: 'get',
    done: (response) => {
      response.Properties.SavedLevels.Value[0].Value.SavedActors.Value.forEach(async (actor) => {
        const match = actor.ActorPath.match(/\/Game\/Building\/ActorBlueprints\/Player\/(?<material>\w+)\/L\d\/PBWA_[a-z\d]+_(?<type>[a-z0-9_]+).+/i);

        if (!match) {
          return;
        }

        const { material, type } = match.groups;

        const object = await getPlayerBuild(type, material, camera);

        object.name = type;

        object.position.set(
          actor.Location.X + 30000,
          actor.Location.Y + 30000,
          actor.Location.Z,
        );

        object.rotation.setFromQuaternion(new THREE.Quaternion(
          actor.Rotation.X,
          actor.Rotation.Y,
          actor.Rotation.Z,
          actor.Rotation.W,
        ));

        object.scale.set(
          actor.Scale.X,
          actor.Scale.Y,
          actor.Scale.Z,
        );

        if (object.userData.missing) {
          camera.position.x = object.position.x;
          camera.position.y = -object.position.y;
          camera.position.z = object.position.z;
        }

        scene.add(object);
      });
    }
  });
};

export default test;
