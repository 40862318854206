import * as THREE from 'three';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
import { Font, FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import debugSpotMaterial from '../materials/debug-spot';

let font;

const loader = new FontLoader();

loader.load('https://threejs.org/examples/fonts/helvetiker_regular.typeface.json', (response) => {
  font = response;
});

const createText = (text) => {
  const geometry = new TextGeometry(text, {
    font: font,
    size: 100,
    height: 20,
    bevelEnabled: true,
    bevelThickness: 2,
    bevelSize: 1.5,
    curveSegments: 4,
  });

  geometry.computeBoundingBox();

  const centerOffset = -0.5 * (geometry.boundingBox.max.x - geometry.boundingBox.min.x);

  // geometry.translate(centerOffset, 0, 0);

  const mesh = new THREE.Mesh(geometry, debugSpotMaterial);

  mesh.scale.y = -1;

  return mesh;
};

export default createText;
