import * as THREE from 'three';

import createDebugSpot from '../utils/create-debug-spot';
import createText from '../utils/create-text';
import getPlayerBuild from '../utils/get-player-build';

const objects = [];

const playerBuilds = async (scene, playerBuilds, camera) => {
  const promises = playerBuilds.map(async ({ type: pathName, rotation, location, destroyed, bMirrored, scale }) => {
    if (destroyed) {
      return;
    }

    const { type, material } = pathName.match(/\/Game\/Building\/ActorBlueprints\/Player\/(?<material>\w+)\/L\d\/PBWA_[\w\d]+_(?<type>\w+).+/i).groups;

    const object = await getPlayerBuild(type, material, camera);

    object.name = type;

    object.position.set(
      location.x,
      location.y,
      location.z,
    );

    object.rotation.set(
      THREE.MathUtils.degToRad(rotation.pitch),
      THREE.MathUtils.degToRad(rotation.roll),
      THREE.MathUtils.degToRad(rotation.yaw),
    );

    object.scale.set(
      scale.x,
      scale.y,
      scale.z,
    );

    if (object.userData.missing) {
      camera.position.x = object.position.x;
      camera.position.y = -object.position.y;
      camera.position.z = object.position.z;
    }

    if (bMirrored) {
      // object.scale.x = -1;

      // const text = createText('MIRRORED');

      // text.position.copy(object.position);
      // text.rotation.copy(object.rotation);

      // scene.add(text);
    }

    const debugSpot = createDebugSpot();

    debugSpot.position.set(
      location.x,
      location.y,
      location.z,
    );
    debugSpot.rotation.copy(object.rotation);

    // scene.add(debugSpot);
    scene.add(object);
    objects.push(object);
  });

  await Promise.all(promises);

  console.log(objects)
};

export default playerBuilds;
