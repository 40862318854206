import * as THREE from 'three';
const { default: createPickup } = require('../utils/create-pickup');

const pickups = (scene, pickups) => {
  pickups.forEach(({ ReplicatedMovement }) => {
    const item = createPickup();

    item.position.set(
      ReplicatedMovement.location.x,
      ReplicatedMovement.location.y,
      ReplicatedMovement.location.z,
    ).multiplyScalar(10);

    item.rotation.set(
      THREE.MathUtils.degToRad(ReplicatedMovement.rotation.pitch),
      THREE.MathUtils.degToRad(ReplicatedMovement.rotation.yaw),
      THREE.MathUtils.degToRad(ReplicatedMovement.rotation.roll),
    );

    scene.add(item);
  });
};

export default pickups;
