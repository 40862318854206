import * as THREE from 'three';
import initScene from "./init-scene";
import createTerrain from "./create-terrain";
import loadReplay from "./load-replay";
import get3dDistance from './utils/get-3d-distance';
import loadBuildings from './load-buildings';
import createDebugSpot from './utils/create-debug-spot';
import loadItems from './load-items';
import test from './test';
import createTerrainNew from './create-terrain-new';
import createTerrainNewest from './create-terrain-newest';

const turnSpeed = Math.PI / 1.5;
const moveSpeed = 2500;
const shiftMultiplier = 5;
const maxRenderDistance = 250000;
const minRenderDistance = 1000;
const maxDelta = 5;
const targetFramerate = 30;

document.onreadystatechange = async () => {
  const downKeys = {};

  if (document.readyState === 'complete') {
    const { scene, camera, renderer } = initScene();

    camera.position.set(0, 0, 2688);

    window.addEventListener('keydown', (e) => {
      downKeys[e.key.toLowerCase()] = true;
    });

    window.addEventListener('keyup', (e) => {
      downKeys[e.key.toLowerCase()] = false;
    });

    const center = createDebugSpot();

    center.userData.cantDespawn = true;

    scene.add(center);

    const axesHelper = new THREE.AxesHelper(100);

    axesHelper.userData.cantDespawn = true;

    scene.add(axesHelper);

    console.log(scene, camera, renderer);

    // await createTerrainNewest(scene, camera);
    await createTerrainNew(scene, camera);
    // await createTerrain(scene, camera);
    // loadReplay(scene, camera);
    // loadBuildings(scene, camera);
    // loadItems(scene, camera);
    // test(scene, camera);

    const lastRenderTimes = [];
    let lastAttemptTime = 0;
    let raycastHits = [];
    let lastTime = 0;
    let sinceLastFramerate = 0;
    let countedFrames = 0;
    let lastFrameTime = 0;
    let totalFrameTime = 0;
    let renderDistance = 10000;
    let sinceLastFrame = 0;

    const camLookAtTarget = new THREE.Vector3();

    const rayCaster = new THREE.Raycaster(new THREE.Vector3(), new THREE.Vector3(), 100, 200000);

    let yAxis = 0;
    let xAxis = 0;

    window.addEventListener('click', (event) => {
      const pointer = new THREE.Vector2((event.clientX / window.innerWidth) * 2 - 1, - (event.clientY / window.innerHeight) * 2 + 1);

      raycastHits.forEach((obj) => {
        scene.remove(scene.remove(obj));
      });

      raycastHits = [];

      rayCaster.setFromCamera(pointer, camera);

      const intersects = rayCaster.intersectObjects(scene.children);

      for (let i = 0; i < intersects.length; i++) {
        const intersect = intersects[i];

        const debugSpot = createDebugSpot();

        console.log(intersect, intersect.object.name);

        scene.add(debugSpot);
        raycastHits.push(debugSpot);

        debugSpot.position.copy(intersect.point);

        if (intersect.object.name === 'terrain') {
          const terrain = intersect.object;

          console.log(terrain.userData.heights[Math.floor((((intersect.point.x + 262500 / 2)) / 262500) * terrain.userData.rowLength)][Math.floor((262500 - (intersect.point.y + 262500 / 2)) / 262500 * terrain.userData.rowLength)]);
        }
      }
    })

    const animate = (time) => {
      requestAnimationFrame(animate);

      sinceLastFrame += time - lastAttemptTime;

      lastAttemptTime = time;

      if (sinceLastFrame < 1000 / targetFramerate) {
        return;
      }

      const deltaTime = time - lastTime;

      sinceLastFrame = 0;
      lastTime = time;
      sinceLastFramerate += deltaTime;
      countedFrames += 1;

      let turnDist = (turnSpeed / 1000) * deltaTime;
      let moveDist = (moveSpeed / 1000) * deltaTime;

      if (downKeys.shift) {
        // turnDist *= shiftMultiplier;
        moveDist *= shiftMultiplier;
      }

      if (downKeys.backspace) {
        moveDist *= shiftMultiplier;
      }

      if (downKeys.w) {
        camera.translateZ(-moveDist);
      }

      if (downKeys.s) {
        camera.translateZ(moveDist);
      }

      if (downKeys.a) {
        camera.translateX(-moveDist);
      }

      if (downKeys.d) {
        camera.translateX(moveDist);
      }

      if (downKeys.q) {
        camera.translateY(-moveDist);
      }

      if (downKeys.e) {
        camera.translateY(moveDist);
      }

      if (downKeys.arrowleft) {
        yAxis += turnDist;
      }

      if (downKeys.arrowright) {
        yAxis -= turnDist;
      }

      if (downKeys.arrowup) {
        xAxis += turnDist;
      }

      if (downKeys.arrowdown) {
        xAxis -= turnDist;
      }

      xAxis = Math.max(-Math.PI / 2, Math.min(Math.PI / 2, xAxis));

      camLookAtTarget.set(camera.position.x, camera.position.y, camera.position.z);

      camLookAtTarget.x += Math.cos(yAxis);
      camLookAtTarget.y += Math.sin(yAxis);

      camera.lookAt(camLookAtTarget);
      camera.rotateX(xAxis);

      // const camForward = new THREE.Vector3(camera.position.x, camera.position.y, camera.position.z);
      const oldCamPos = new THREE.Vector3(camera.position.x, camera.position.y, camera.position.z);

      camera.translateZ(1);

      const camForward = new THREE.Vector3(camera.position.x, camera.position.y, camera.position.z);

      camera.position.copy(oldCamPos);

      camForward.sub(oldCamPos);

      // camForward.sub(camLookAtTarget);

      axesHelper.position.set(camera.position.x + camForward.x * -1000, -camera.position.y - camForward.y * -1000, camera.position.z + camForward.z * -1000);

      scene.children.forEach((child) => {
        if (child.userData.cantDespawn) {
          return;
        }

        const pos = new THREE.Vector3();

        child.getWorldPosition(pos);

        const distance = get3dDistance(camera.position, pos);

        child.visible = distance < renderDistance;
      });

      renderer.render(scene, camera);

      lastFrameTime = performance.now() - time;
      totalFrameTime += lastFrameTime;

      if (sinceLastFramerate > 1000) {
        lastRenderTimes.push(totalFrameTime);

        if (lastRenderTimes.length > 3) {
          lastRenderTimes.shift();
        }

        const averageRenderTime = lastRenderTimes.reduce((a, b) => a + b, 0) / lastRenderTimes.length;

        const avgFrameTime = averageRenderTime / countedFrames;

        const delta = ((1000 / targetFramerate) - avgFrameTime);

        if (Math.abs(delta) > 1) {
          renderDistance += (Math.min(Math.abs(delta), maxDelta) ** 5) * (delta > 0 ? 1 : -1);
        }

        if (renderDistance > maxRenderDistance) {
          renderDistance = maxRenderDistance;
        }

        if (renderDistance < minRenderDistance) {
          renderDistance = minRenderDistance;
        }

        console.log(`${countedFrames} FPS, ${totalFrameTime / countedFrames}ms per frame, render distance: ${renderDistance}`);

        sinceLastFramerate = 0;
        countedFrames = 0;
        totalFrameTime = 0;
      }
    }

    requestAnimationFrame(animate);
  }
};
