import * as THREE from 'three';
import { ajax } from 'jlight';
import mapMaterial from './materials/map';

const mapSize = 250000;
const planeSize = 4096;
const gridSize = 200;

const halfMapSize = mapSize / 2;

export default (scene, camera) => {
  return new Promise((resolve) => {
    ajax({
      url: '/object-heightmap.json',
      method: 'get',
      done: (responsee) => {
        const reload = (test) => {
          const terrainGeometry = new THREE.PlaneGeometry(mapSize, mapSize, planeSize - 1, planeSize - 1);

          for (let x = 0; x < planeSize; x += 1) {
            for (let y = 0; y < planeSize; y += 1) {
              const index = (planeSize * y + x) * 3;
              const theX = (x / (planeSize - 1)) * mapSize - halfMapSize;
              const theY = (y / (planeSize - 1)) * mapSize - halfMapSize;

              const gridX = theX / gridSize;
              const gridY = theY / gridSize;

              let height = 0;

              let exactMatch = responsee[`${gridX},${gridY}`];

              if (exactMatch) {
                height = exactMatch;
              } else {
                // theres no exact match so we need to find the height based on the points around it
                const nextX = Math.ceil(gridX);
                const nextY = Math.ceil(gridY);

                const prevX = Math.floor(gridX);
                const prevY = Math.floor(gridY);

                const nextXNextY = responsee[`${nextX},${nextY}`];
                const nextXPrevY = responsee[`${nextX},${prevY}`];
                const prevXNextY = responsee[`${prevX},${nextY}`];
                const prevXPrevY = responsee[`${prevX},${prevY}`];

                if (nextXNextY && nextXPrevY && prevXNextY && prevXPrevY) {
                  // Calculate the fractional distances of the position from the floor values
                  let dx = gridX - prevX;
                  let dy = gridY - prevY;

                  // Interpolate along x for both y = prevY and y = nextY
                  let interpAtPrevY = prevXPrevY + dx * (nextXPrevY - prevXPrevY);
                  let interpAtNextY = prevXNextY + dx * (nextXNextY - prevXNextY);

                  // Interpolate along y between the two intermediate values
                  let finalInterpolatedHeight = interpAtPrevY + dy * (interpAtNextY - interpAtPrevY);

                  height = finalInterpolatedHeight;
                }


              }

              // if (height < 0) {
              //   terrainGeometry.attributes.position.array[index + 2] = 0;
              // } else {
                terrainGeometry.attributes.position.array[index + 2] = height;
              // }
            }
          }

          // Object.entries(responsee).forEach(([key, height]) => {
          //   const parts = key.split(',');

          //   const rawX = parseInt(parts[0], 10);
          //   const rawY = parseInt(parts[1], 10);

          //   if (rawX < -halfMapSize || rawX > halfMapSize || rawY < -halfMapSize || rawY > halfMapSize) {
          //     return;
          //   }

          //   const x = Math.round((parseInt(parts[0], 10) + halfMapSize) / (mapSize / planeSize));
          //   const y = Math.round((parseInt(parts[1], 10) + halfMapSize) / (mapSize / planeSize));

          //   const index2 = (planeSize * y + x) * 3;

          //   if (height < 0) {
          //     terrainGeometry.attributes.position.array[index2 + 2] = 0;
          //   } else {
          //     terrainGeometry.attributes.position.array[index2 + 2] = height;
          //   }
          // });

          const oldTerrain = scene.getObjectByName('terrain');

          if (oldTerrain) {
            scene.remove(oldTerrain);
          }

          const terrain = new THREE.Mesh(terrainGeometry, mapMaterial);

          terrain.name = 'terrain';
          terrain.userData.cantDespawn = true
          terrain.scale.y = -1;
          // terrain.position.x = -100;
          // terrain.position.y = 9750;

          scene.add(terrain);
        }

        reload({ sub: 32760, dev: 1.28 });

        global.reload = reload;

        // for (let x = -262500 / 2; x < 262500 / 2; x += 2000) {
        //   for (let y = -262500 / 2; y < 262500 / 2; y += 2000) {
        //     const debugObject = createDebugSpot();

        //     debugObject.position.set(
        //       x,
        //       y,
        //       terrain.userData.heights[Math.floor((((x + 262500 / 2)) / 262500) * terrain.userData.rowLength)][Math.floor((262500 - (y + 262500 / 2)) / 262500 * terrain.userData.rowLength)] - 33388,
        //     );

        //     scene.add(debugObject);
        //   }
        // }

        resolve();
      }
    });
  });
}
