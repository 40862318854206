import * as THREE from 'three';

const loader = new THREE.TextureLoader();

// const texture = loader.load('/textures/map.png');
const texture = loader.load('/textures/33.0.png');

const normalMapSize = 299926;
const renderedMapSize = 250000;

// if using the new orientation then x needs to by -y and y needs to be x
const minimapOffsetX = -508;
const minimapOffsetY = 8500;

texture.repeat.set(renderedMapSize / normalMapSize, renderedMapSize / normalMapSize);
texture.center.set(0.5, 0.5);
// texture.rotation = -Math.PI / 2;
texture.offset.set(-minimapOffsetY / normalMapSize, -minimapOffsetX / normalMapSize);

console.log(texture);

const mapMaterial = new THREE.MeshBasicMaterial({
  // color: 0x00ff00,
  // wireframe: true,
  map: texture,
});

export default mapMaterial;
