import * as THREE from 'three';

import debugSpotGeometry from '../geometry/debug-spot';
import debugSpotMaterial from '../materials/debug-spot';

const createDebugSpot = () => {
  const floor = new THREE.Mesh(
    debugSpotGeometry,
    debugSpotMaterial,
  );

  return floor;
}

export default createDebugSpot;
