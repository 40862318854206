import { ajax } from "jlight";
import * as THREE from 'three';
import getPlayerBuild from './utils/get-player-build';

const loadBuildings = (scene, camera) => {
  ajax({
    url: '/buildings.json',
    method: 'get',
    done: (response) => {
      response.forEach(async (buildingData) => {
        let building;

        switch (buildingData.BuildingType) {
          case 'Wall':
            building = await getPlayerBuild('Solid', 'Wood');
            break;

          case 'Floor':
            building = await getPlayerBuild('Floor', 'Wood');
            break;

          case 'Ramp':
            building = await getPlayerBuild('StairW', 'Wood');
            break;

          default:
            building = await getPlayerBuild(buildingData.BuildingType, 'Wood');
        }

        building.position.set(
          buildingData.Position.X,
          buildingData.Position.Y,
          buildingData.Position.Z,
        );

        building.name = buildingData.StaticId;

        building.rotation.set(
          THREE.MathUtils.degToRad(buildingData.rotation?.Pitch || 0),
          THREE.MathUtils.degToRad(buildingData.rotation?.Roll || 0),
          THREE.MathUtils.degToRad(buildingData.rotation?.Yaw || 0),
        );

        scene.add(building);
      })
    }
  });
};

export default loadBuildings;
